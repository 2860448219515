  @keyframes noise-anim {
      0% {
          clip: rect(63px,9999px,1px,0)
      }

      5% {
          clip: rect(48px,9999px,82px,0)
      }

      10% {
          clip: rect(88px,9999px,96px,0)
      }

      15% {
          clip: rect(50px,9999px,14px,0)
      }

      20% {
          clip: rect(23px,9999px,12px,0)
      }

      25% {
          clip: rect(70px,9999px,29px,0)
      }

      30% {
          clip: rect(84px,9999px,4px,0)
      }

      35% {
          clip: rect(90px,9999px,77px,0)
      }

      40% {
          clip: rect(7px,9999px,80px,0)
      }

      45% {
          clip: rect(62px,9999px,88px,0)
      }

      50% {
          clip: rect(31px,9999px,13px,0)
      }

      55% {
          clip: rect(86px,9999px,34px,0)
      }

      60% {
          clip: rect(20px,9999px,82px,0)
      }

      65% {
          clip: rect(92px,9999px,84px,0)
      }

      70% {
          clip: rect(69px,9999px,43px,0)
      }

      75% {
          clip: rect(35px,9999px,54px,0)
      }

      80% {
          clip: rect(63px,9999px,23px,0)
      }

      85% {
          clip: rect(23px,9999px,1px,0)
      }

      90% {
          clip: rect(37px,9999px,47px,0)
      }

      95% {
          clip: rect(64px,9999px,12px,0)
      }

      100% {
          clip: rect(61px,9999px,60px,0)
      }
  }

  @keyframes noise-anim-2 {
      0% {
          clip: rect(2px,9999px,20px,0)
      }

      5% {
          clip: rect(45px,9999px,15px,0)
      }

      10% {
          clip: rect(95px,9999px,37px,0)
      }

      15% {
          clip: rect(20px,9999px,37px,0)
      }

      20% {
          clip: rect(66px,9999px,52px,0)
      }

      25% {
          clip: rect(15px,9999px,9px,0)
      }

      30% {
          clip: rect(56px,9999px,36px,0)
      }

      35% {
          clip: rect(69px,9999px,58px,0)
      }

      40% {
          clip: rect(20px,9999px,1px,0)
      }

      45% {
          clip: rect(54px,9999px,75px,0)
      }

      50% {
          clip: rect(55px,9999px,98px,0)
      }

      55% {
          clip: rect(55px,9999px,66px,0)
      }

      60% {
          clip: rect(41px,9999px,78px,0)
      }

      65% {
          clip: rect(86px,9999px,74px,0)
      }

      70% {
          clip: rect(41px,9999px,76px,0)
      }

      75% {
          clip: rect(20px,9999px,39px,0)
      }

      80% {
          clip: rect(7px,9999px,60px,0)
      }

      85% {
          clip: rect(40px,9999px,41px,0)
      }

      90% {
          clip: rect(19px,9999px,22px,0)
      }

      95% {
          clip: rect(51px,9999px,7px,0)
      }

      100% {
          clip: rect(33px,9999px,19px,0)
      }
  }

  .glitch:before {
      content: attr(data-text);
      position: absolute;
      left: -2px;
      text-shadow: 1px 0 blue;
      top: 0;
      color: white;
      background: black;
      overflow: hidden;
      clip: rect(0,900px,0,0);
      animation: noise-anim-2 3s infinite linear alternate-reverse
  }

  :root {
      --glitch-width: 100svw;
      --glitch-height: 100svh;
      --gap-horizontal: 10px;
      --gap-vertical: 5px;
      --time-anim: 4s;
      --delay-anim: 2s;
      --blend-mode-1: none;
      --blend-mode-2: none;
      --blend-mode-3: none;
      --blend-mode-4: none;
      --blend-mode-5: overlay;
      --blend-color-1: transparent;
      --blend-color-2: transparent;
      --blend-color-3: transparent;
      --blend-color-4: transparent;
      --blend-color-5: rgba(76, 0, 255, 0.37);
  }

  .glitch--container {
      width: 100svw;
      height: 100svh;
      max-width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
  }

  .glitch--container .glitch__item {
      background: url('./assets/bg.webp') no-repeat 50% 50% / cover;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
  }

  .glitch--container .glitch__item:nth-child(n+2) {
      opacity: 0;
      animation-duration: var(--time-anim);
      animation-delay: var(--delay-anim);
      animation-timing-function: linear;
      animation-iteration-count: infinite;
  }

  .glitch--container .glitch__item:nth-child(2) {
      background-color: var(--blend-color-2);
      background-blend-mode: var(--blend-mode-2);
      animation-name: glitch-anim-2;
  }

  .glitch--container .glitch__item:nth-child(2) {
      background-color: var(--blend-color-3);
      background-blend-mode: var(--blend-mode-3);
      animation-name: glitch-anim-3;
  }

  .glitch--container .glitch__item:nth-child(4) {
      background-color: var(--blend-color-4);
      background-blend-mode: var(--blend-mode-4);
      animation-name: glitch-anim-4;
  }

  .glitch--container .glitch__item:nth-child(5) {
      background-color: var(--blend-color-5);
      background-blend-mode: var(--blend-mode-5);
      animation-name: glitch-anim-flash;
  }

  @keyframes glitch-anim-flash {
      0%, 5% {
          opacity: 0.2;
          transform: translate3d(var(--glitch-horizontal), var(--glitch-height), 0);
      }

      5.5%, 100% {
          opacity: 0;
          transform: translate3d(0, 0, 0);
      }
  }

  .glitch--container .glitch__title {
      font-family: 'Creepster', cursive;
      font-size: 100px;
      color: #ffffff;
      position: relative;
      text-transform: uppercase;
  }

  .glitch--container .glitch__title:before, .glitch--container .glitch__title:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
  }

  .glitch--container .glitch__title:before {
      left: 2px;
      text-shadow: -1px 0 #00ffea;
      clip: rect(24px, 550px, 90px, 0);
      animation: glitch-anim 2s linear infinite alternate-reverse;
  }

  .glitch--container .glitch__title:after {
      left: -2px;
      text-shadow: -1px 0 #fe3a7f;
      clip: rect(85px, 550px, 140px, 0);
      animation: glitch-anim 2s 1s linear infinite alternate-reverse;
  }

  @keyframes glitch-anim {
      0% {
          clip: rect(37px, 9999px, 6px, 0);
      }

      5% {
          clip: rect(47px, 9999px, 120px, 0);
      }

      10% {
          clip: rect(94px, 9999px, 43px, 0);
      }

      15% {
          clip: rect(143px, 9999px, 90px, 0);
      }

      20% {
          clip: rect(70px, 9999px, 86px, 0);
      }

      25% {
          clip: rect(77px, 9999px, 35px, 0);
      }

      30% {
          clip: rect(107px, 9999px, 150px, 0);
      }

      35% {
          clip: rect(153px, 9999px, 89px, 0);
      }

      40% {
          clip: rect(107px, 9999px, 148px, 0);
      }

      45% {
          clip: rect(9px, 9999px, 7px, 0);
      }

      50% {
          clip: rect(141px, 9999px, 138px, 0);
      }

      55% {
          clip: rect(55px, 9999px, 88px, 0);
      }

      60% {
          clip: rect(68px, 9999px, 3px, 0);
      }

      65% {
          clip: rect(77px, 9999px, 110px, 0);
      }

      70% {
          clip: rect(120px, 9999px, 169px, 0);
      }

      75% {
          clip: rect(131px, 9999px, 162px, 0);
      }

      80% {
          clip: rect(132px, 9999px, 51px, 0);
      }

      85% {
          clip: rect(87px, 9999px, 49px, 0);
      }

      90% {
          clip: rect(160px, 9999px, 90px, 0);
      }

      95% {
          clip: rect(64px, 9999px, 39px, 0);
      }

      100% {
          clip: rect(117px, 9999px, 21px, 0);
      }
  }

  @keyframes glitch-anim-2 {
      0% {
          opacity: 1;
          transform: translate3d(var(--gap-horizontal), 0, 0);
          -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
          clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
      }

      2% {
          -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
          clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
      }

      4% {
          -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
          clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
      }

      6% {
          -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
          clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
      }

      8% {
          -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
          clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
      }

      10% {
          -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
          clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
      }

      12% {
          -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
          clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
      }

      14% {
          -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
          clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
      }

      16% {
          -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
          clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
      }

      18% {
          -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
          clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
      }

      20% {
          -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
          clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
      }

      21.9% {
          opacity: 1;
          transform: translate3d(var(--gap-horizontal), 0, 0);
      }

      22%, 100% {
          opacity: 0;
          transform: translate3d(0, 0, 0);
          -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
          clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      }
  }

  @keyframes glitch-anim-3 {
      0% {
          opacity: 1;
          transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
          -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
          clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
      }

      3% {
          -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
          clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
      }

      5% {
          -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
          clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
      }

      7% {
          -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
          clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      }

      9% {
          -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
          clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
      }

      11% {
          -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
          clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
      }

      13% {
          -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
          clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
      }

      15% {
          -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
          clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
      }

      17% {
          -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
          clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
      }

      19% {
          -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
          clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
      }

      20% {
          -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
          clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
      }

      21.9% {
          opacity: 1;
          transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
      }

      22%, 100% {
          opacity: 0;
          transform: translate3d(0, 0, 0);
          -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
          clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      }
  }

  @keyframes glitch-anim-4 {
      0% {
          opacity: 1;
          transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1);
          -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
          clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
      }

      1.5% {
          -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
          clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
      }

      2% {
          -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
          clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
      }

      2.5% {
          -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
          clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      }

      3% {
          -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
          clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
      }

      5% {
          -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
          clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
      }

      5.5% {
          -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
          clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
      }

      7% {
          -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
          clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
      }

      8% {
          -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
          clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
      }

      9% {
          -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
          clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
      }

      10.5% {
          -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
          clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
      }

      11% {
          -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
          clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
      }

      13% {
          -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
          clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
      }

      14% {
          -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
          clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
      }

      14.5% {
          -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
          clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
      }

      15% {
          -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
          clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
      }

      16% {
          -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
          clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
      }

      18% {
          -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
          clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
      }

      20% {
          -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
          clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
      }

      21.9% {
          opacity: 1;
          transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1);
      }

      22%, 100% {
          opacity: 0;
          transform: translate3d(0, 0, 0);
          -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
          clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      }
  }
