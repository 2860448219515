@tailwind base;
@tailwind components;
@tailwind utilities;

section#main {
    background-image: url("./assets/bg.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    align-items: self-start;
}
.icon {
    transition: ease .2s;
}

*:hover {
    transition: ease .2s;
}